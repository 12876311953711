body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* 記事のスタイル */

.mainContents main {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: lighter;
  margin: 0 auto;
  max-width: 960px;
}
footer {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: lighter;
  margin: 1em auto;
  margin-top: 3em;
  max-width: 960px;
}

.mainContents h1 {
  font-size: 36px;
}

.mainContents h2 {
  font-size: 26px;
  margin-top: 2em;
}

.mainContents h3 {
  font-size: 22px;
  margin-top: 2em;
}

.mainContents h4 {
  font-size: 18px;
  margin-top: 2em;
}

.mainContents {
  font-size: 18px;
}

.mainContents a {
  color: #f50057;
  text-decoration: none;
}
.mainContents a:hover {
  text-decoration: underline;
}

.mainContents pre {
  margin: 2em 0;
}

.mainContents code {
  padding: 0 0.25em;
}
.mainContents pre>code {
  padding: 1em!important;
  font-size: 16px;
}


.mainContents strong {}

.mainContents blockquote {
  background-color: #2d2d2d;
  padding: 0.5em;
  margin: 0;
  color: #EFEFEF;
}

.mainContents blockquote > p {
  margin: 1em;
}

.mainContents img {
  display: block;
  margin: 2em auto;
  max-width: 100%;
}